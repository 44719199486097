<script setup>
import MotoLogo from './Vector/Moto-Logo.vue';
import menus from "~/configs/motoMenu";
const menuVisible = ref(false);
const route = useRoute();
const returnToVehisBar = ref(true);
</script>

<template>
  <LazyMenuMobile :custom-menu="menus" v-model:visible="menuVisible" no-moto-link/>
  <AppHeaderBar v-if="route.name === 'index'"/>
  <div class="sticky top-0 bg-white shadow-box z-50">
    <div class="h-10 bg-cool-gray flex items-center" v-if="returnToVehisBar">
      <Container>
        <div class="flex items-center relative">
          <div class="w-5 h-5 cursor-pointer flex items-center">
            <IconArrowLeft class="!w-3.5 !h-3.5" />
          </div>

          <NuxtLink to="/" class="inline-block mr-1"><span class="font-extralight inline-block mr-1">Przejdź na</span>VEHIS.pl</NuxtLink>
            <IconClose @click="returnToVehisBar = false" class="absolute right-0 top-0 cursor-pointer !w-5 h-5" />
        </div>
      </Container>
    </div>
    <Container no-paddings>
      <AppHeaderMotoMenu @hamburgerClick="menuVisible = !menuVisible"/>
    </Container>
  </div>
</template>
