export default [
    {label: 'Wiadomości ', path: {name: 'blog-news'}},
    {label: 'Premiery', path: {name: 'blog-premiere'}},
    {label: 'Testy ', path: {name: 'blog-tests'}},
    {label: 'Na drodze ', path: {name: 'blog-road'}},
    {label: 'E-auto ', path: {name: 'blog-eauto'}},
    {label: 'Porady ', path: {name: 'blog-advices'}},
    {label: 'Zdjęcia ', path: {name: 'blog-photos'}},
    {label: 'Filmy ', path: {name: 'blog-movies'}},
    {label: 'Clacson ', path: {name: 'blog-clacson'}},
  ]
  