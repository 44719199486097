export const useBlogCategories = () => {
    const blogCategories = useState('blogCategories', () => null);
  
    const fetchBlogCategories = async () => {
      if (!blogCategories.value) {
        const backend = useBackend();
        const { data } = await backend.getBlogCategories();

        blogCategories.value = data;
      }
    };
  
    return {
        blogCategories,
        fetchBlogCategories,
    };
  };
  